/* --- Header text feature --- */
.call-out {
    @extend %padding-header;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 1.2em;
    text-align: center;
    color: $header-desc-text-color;
    background: $header-desc-background-color no-repeat;
    background-size: $feature-image-size;
  
    /* --- Responsive --- */
    @media screen and (min-width: $break) {
      background-size: 120% auto;
    }
  
    @media screen and (max-width: $break) {
      background-size: 200% auto;
    }
  
    @media screen and (max-width: $sm-break) {
      background-size: 300% auto;
    }
  
    //For white images
    p,
    a,
    li,
    h1,
    h2,
    h3,
    h4,
    h5 {
      @extend .header-txt-shadow;
    }
  
    p:last-child {
      margin-bottom: 0;
    }
  }
  
  #main {
    // background-position: 100% 0, 0 center, center top;
    background-position: center;
  }
  
  /* --- Post listing --- */
  .posts {
    .post-teaser {
      @extend %padding-post;
      width: 100%;
      margin-bottom: 0;
      display: inline-block;
      background-size: $feature-image-size;
      border-bottom: 1px solid $border-color;
  
      p a {
        @extend .body-link;
      }
  
      .post-img {
        vertical-align: middle;
        border-radius: 10px;
        overflow: hidden;
  
        @media (min-width: $break) {
          height: 250px;
        }
  
        //Smaller screen
        @media screen and (max-width: $break) {
          height: 150px;
        }
  
        img {
          width: 100%;
          padding: 0;
          vertical-align: middle;
        }
  
      }
  
      .post-img img {
        -moz-transition-timing-function: ease-out;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
  
        -moz-transition: all 2s;
        -webkit-transition: all 2s;
        transition: all 2s;
  
        &:hover {
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
  
    .excerpt {
      margin-top: 1em;
    }
  }