// Mix-ins
%padding-nav {
  padding: $padding-small $padding-x-large;
  @media (max-width: $xl-break) {
    padding: $padding-small ($padding-large * 2);
  }
  @media (max-width: $l-break) {
    padding: $padding-small $padding-large;
  }
  @media (max-width: $m-break) {
    padding: $padding-small $padding-medium;
  }
  @media (max-width: $sm-break) {
    padding: $padding-small $padding-x-small;
  }
}

%padding-post {
  padding: $padding-x-small $padding-x-large;
  @media (max-width: $xl-break) {
    padding: $padding-x-small ($padding-large * 2);
  }
  @media (max-width: $l-break) {
    padding: $padding-x-small $padding-large;
  }
  @media (max-width: $m-break) {
    padding: $padding-x-small $padding-medium;
  }
  @media (max-width: $sm-break) {
    padding: $padding-x-small $padding-small;
  }
}

%padding-header {
  padding: $padding-medium $padding-large;
  @media (min-width: $xl-break) {
    padding: $padding-large $padding-large;
  }
  @media (max-width: $sm-break) {
    padding: $padding-large;
  }
}

.header-txt-shadow {
  text-shadow: 1px 1px 2px $text-shadow;
}

// Buttons
.button {
  border-radius: 0.3em;
  border: 1px solid;
  display: inline-block;
  margin: 1em 0;
  padding: 0.5em 0.75em;
}

a.button {
  @extend .button-link;
}

.button-link {
  &:hover {
    background: $link-color;
    border: 1px solid $link-color;
    color: $background-color;
    text-decoration: none;
  }
}

.body-link {
  &:hover {
    text-decoration: underline;
    color: darken($link-color, 15%);
  }
}

.post-link {
  &:hover {
    text-decoration: none;
    color: lighten($link-color, 10%);
  }
}

// States
.disabled {
  opacity: 0.7;
}

// Custom
.text-center {
  text-align: center;
}

.right {
  float: right;
}
