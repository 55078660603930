.comments {
  @extend %padding-post;
  border-bottom: 1px solid $border-color;
  float: left;
  width: 100%;
}

article {
  @extend .comments;

  header {
    margin-bottom: 6%;
    text-align: center;
  }

  a:hover {
    @extend .body-link;
  }

  .footnotes {
    font-size: 0.9em;
  }
}

footer {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.9em;
  display: inline-block;
  text-align: center;

  .meta {
    display: inline;
  }

  a.button {
    margin-left: 1em;
  }
}

header {
  h1 {
    margin: 0;
  }
}

.meta {
  color: $meta; // rgba($text-color, .65);
  letter-spacing: 0.1em;
  margin: 0;
  text-transform: uppercase;
}

/* --- Post info --- */
.post-info {
  /* For border in author
  border: 1px solid $border-color;
  padding-left: 0.5em;
  */
  border-radius: 1em;
  padding-right: 0.5em;
  display: inline-flex;

  a {
    display: flex;
    align-items: center;
    img {
      height: auto;
    }
  }

  img {
    border-radius: 1em;
    padding: 0px;
    width: 1.5em;
    height: 100%;
    margin-right: 0.5em;
  }
}

/* --- Feature image --- */
.feature-image {
  padding: 0;

  .post-link {
    color: $feature-image-text-color;
  }

  header {
    color: $feature-image-text-color;
    margin-bottom: 0;
    padding: $padding-large/2.5 $padding-large;

    .meta {
      color: rgba($feature-image-text-color, .7);
    }
  }

  .post-content {
    @extend %padding-post;
    padding-bottom: 0;
  }

  footer {
    @extend %padding-post;
    padding-top: 0;
  }
}

/*-- feature-image Responsive scrolling --*/
.feature-image header {
  @media screen and (max-width: $break) {
    padding: $padding-large*1.5 $padding-small;
  }

  @media screen and (min-width: $break) {
    background-size: 120% auto;
  }

  @media screen and (max-width: $break) {
    background-size: 200% auto;
  }

  @media screen and (max-width: $sm-break) {
    background-size: 400% auto;
  }
}
